import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Modal, ModalBody, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import React from 'react'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { CheckSquareOffset } from '@phosphor-icons/react'
import SelectTrailerField from './Fields/SelectTrailerField'
import SelectToField from './Fields/SelectToField'
import SelectUserField from './Fields/SelectUserField'
import { Spinner } from 'baseui/spinner'
import { useTranslation } from 'react-i18next'
import {
  CardAppointmentWrapper,
  FromLabel,
  FromText,
  FromWrapper,
  LoadingAppointment,
  ModalHeaderStyled,
  ModalProps
} from './TaskForm.styled'
import { AppointmentCardSmall } from 'components/components/AppointmentCard'
import SelectFacilityField from './Fields/SelectFacilityField'
import { useTrailerContext } from 'components/contexts/yard-trailer.context'
import { ParagraphMedium } from 'baseui/typography'

const TaskForm = () => {
  const { state, actions } = useTrailerContext()
  const {
    taskModal: {
      task,
      trailerNumber,
      forDriver,
      isOpen,
      trailer: { loading: loadingTrailer, trailerInfo }
    }
  } = state

  const { createTask, closeTaskModal } = actions

  const { t } = useTranslation()

  const requiredFieldsPresent = [
    {
      label: t('YardTask.Kanban.TaskModal.Errors.FacilityNotSelected.Text'),
      status: !!task?.facilityId
    },
    {
      label: t('YardTask.Kanban.TaskModal.Errors.TrailerNotSelected.Text'),
      status: !!task?.trailerId
    },
    {
      label: t('YardTask.Kanban.TaskModal.Errors.DestinationNotSelected.Text'),
      status: !!task?.toDockId || !!task?.toSlotId
    }
  ]

  return (
    <Modal
      unstable_ModalBackdropScroll
      onClose={closeTaskModal}
      isOpen={isOpen}
      size={SIZE.auto}
      {...ModalProps}>
      <ModalHeaderStyled>
        {trailerNumber}
        <ParagraphMedium margin={0}>
          {t('YardTrailer.DropModal.Assign.Text', {
            type: forDriver ? t('Common.Fields.Dock.Text') : t('Common.Fields.Yard.Text'),
            place: forDriver ? t('Common.Fields.Door.Text') : t('Common.Fields.Spot.Text')
          })}
        </ParagraphMedium>
      </ModalHeaderStyled>
      <ModalBody>
        <FlexGrid flexGridColumnCount={1} flexGridRowGap={'scale600'}>
          <FlexGridItem>
            {forDriver
              ? t('YardTrailer.DropModal.DriverBody.Text', {
                  place: t('YardTrailer.DropModal.DockPlace.Text')
                })
              : t('YardTrailer.DropModal.YardJockeyBody.Text', {
                  place: t('YardTrailer.DropModal.YardPlace.Text')
                })}
          </FlexGridItem>
          <FlexGridItem>
            <SelectFacilityField />
          </FlexGridItem>
          <FlexGridItem>
            {loadingTrailer && (
              <LoadingAppointment>
                <Spinner size={24} />
              </LoadingAppointment>
            )}
            {trailerInfo && !loadingTrailer && (
              <div>
                {trailerInfo?.appointments?.map(appointment => (
                  <CardAppointmentWrapper>
                    <AppointmentCardSmall appointment={appointment} showAnswers={true} />
                  </CardAppointmentWrapper>
                ))}
                <FromWrapper>
                  <FromLabel>{t('YardTask.Kanban.TaskModal.Fields.From.Text')}</FromLabel>
                  <FromText data-testid="task-form-from">
                    {task?.fromDock?.name ||
                      task?.fromSlot?.name ||
                      t('YardTask.Kanban.TaskModal.Fields.From.Default')}
                  </FromText>
                </FromWrapper>
              </div>
            )}
          </FlexGridItem>
          <FlexGridItem>
            <SelectToField />
          </FlexGridItem>
          {!forDriver && (
            <FlexGridItem>
              <SelectUserField />
            </FlexGridItem>
          )}
        </FlexGrid>
      </ModalBody>
      <ModalFooter>
        <ErrorMessageButton
          data-testid="task-form-create-button"
          endEnhancer={<CheckSquareOffset />}
          errors={requiredFieldsPresent}
          onClick={createTask}
          isLoading={false}
          statefulTooltipProps={{ placement: 'top' }}
          label={t('YardTrailer.DropModal.AssignButton.Text', {
            type: forDriver ? t('Common.Fields.Dock.Text') : t('Common.Fields.Yard.Text'),
            place: forDriver ? t('Common.Fields.Door.Text') : t('Common.Fields.Spot.Text')
          })}
        />
      </ModalFooter>
    </Modal>
  )
}

export default TaskForm
