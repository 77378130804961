export const searchContainerProps = {
  overrides: {
    Block: {
      style: {
        backgroundColor: 'inherit',
        margin: '0px',
        border: '0px'
      }
    }
  }
}

export const searchInputProps = {
  overrides: {
    InputContainer: {
      style: ({ $theme }) => ({
        backgroundColor: 'transparent'
      })
    },
    Root: {
      style: ({ $theme }) => ({
        backgroundColor: $theme.colors.white
      })
    },
    EndEnhancer: {
      style: ({ $theme }) => ({
        opacity: '0.6',
        backgroundColor: 'transparent'
      })
    }
  }
}

export const notFoundLabelProps = {
  overrides: {
    Block: {
      style: () => ({
        color: '#6f7480'
      })
    }
  }
}
