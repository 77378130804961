import { BROKER, CARRIER, SCHEDULER, SHIPPER } from 'components/models/User'
import { useTranslation } from 'react-i18next'

import type * as React from 'react'
import { YARD_JOCKEY_AUDIENCE } from '../../models/Role'

interface NavbarLinkProps {
  label: string
  info: object
  active?: boolean
  icon?: React.ComponentType<any>
  disable?: boolean
  tooltipMessage?: string
}

const isOptionActive = (path: string, exact?: boolean) =>
  exact
    ? window.location.pathname === path
    : window.location.pathname.indexOf(path) > 0 || window.location.pathname === path

export enum NavbarLinksURLs {
  Home = '/',
  DockAssignment = 'dock_assignments',
  ExternalAppointments = 'external_appointments',
  Appointments = 'appointments',
  Analytics = 'analytics',
  Facilities = 'facilities',
  Orders = 'orders',
  Settings = 'settings',
  Yard = 'yard',
  Openscheduling = '/openscheduling',
  OpenschedulingUpdate = 'openscheduling/update'
}

const NavbarLinks = (currentUser): NavbarLinkProps[] => {
  const shipperUser = currentUser?.userType === SHIPPER
  const schedulerUser = [BROKER, CARRIER, SCHEDULER].includes(currentUser?.userType)
  const { t } = useTranslation()

  const links: NavbarLinkProps[] = []
  if (!currentUser) {
    links.push(
      {
        info: { itemId: '/openscheduling' },
        label: t('HeaderNavigation.NavBarLinks.CreateAppointment.Text'),
        active: isOptionActive(NavbarLinksURLs.Openscheduling, true)
      },
      {
        info: { itemId: '/openscheduling/update' },
        label: t('HeaderNavigation.NavBarLinks.UpdateAppointment.Text'),
        active: isOptionActive(NavbarLinksURLs.OpenschedulingUpdate)
      },
      {
        info: { itemId: '/' },
        label: t('HeaderNavigation.NavBarLinks.Exit.Text'),
        active: isOptionActive(NavbarLinksURLs.Home)
      }
    )
  }

  if (currentUser && currentUser.name && currentUser.userType === SHIPPER) {
    links.push({
      info: { itemId: '/' },
      label: t('HeaderNavigation.NavBarLinks.Home.Text'),
      active: isOptionActive(NavbarLinksURLs.Home)
    })
  }

  if (currentUser && shipperUser && currentUser.hasExternalAppointments) {
    links.push({
      info: { itemId: '/external_appointments' },
      label: t('HeaderNavigation.NavBarLinks.ExternalAppointments.Text'),
      active: isOptionActive(NavbarLinksURLs.ExternalAppointments)
    })
  }

  if (schedulerUser) {
    links.push(
      {
        info: { itemId: '/' },
        label: t('HeaderNavigation.NavBarLinks.CreateAppointment.Text'),
        active: isOptionActive(NavbarLinksURLs.Home)
      },
      {
        info: { itemId: '/appointments' },
        label: t('HeaderNavigation.NavBarLinks.UpdateAppointment.Text'),
        active: isOptionActive(NavbarLinksURLs.Appointments)
      }
    )
  }

  if (shipperUser) {
    links.push({
      info: { itemId: '/appointments' },
      label: t('HeaderNavigation.NavBarLinks.Appointments.Text'),
      active: isOptionActive(NavbarLinksURLs.Appointments)
    })
    links.push({
      info: { itemId: `/${NavbarLinksURLs.DockAssignment}` },
      label: t('HeaderNavigation.NavBarLinks.DockAssignment.Text'),
      active: isOptionActive(NavbarLinksURLs.DockAssignment),
      disable: !currentUser?.dockAssignmentActive,
      tooltipMessage: t('HeaderNavigation.NavBarLinks.DockAssignment.Tooltip')
    })
    links.push({
      info: { itemId: `/${NavbarLinksURLs.Yard}` },
      label: t('HeaderNavigation.NavBarLinks.Yard.Text'),
      active: isOptionActive(NavbarLinksURLs.Yard),
      disable: !currentUser?.yardManagementActive,
      tooltipMessage: t('HeaderNavigation.NavBarLinks.Yard.Tooltip')
    })
    links.push({
      info: { itemId: '/analytics' },
      label: t('HeaderNavigation.NavBarLinks.Analytics.Text'),
      active: isOptionActive(NavbarLinksURLs.Analytics)
    })
    links.push({
      info: { itemId: '/facilities' },
      label: t('HeaderNavigation.NavBarLinks.Facilities.Text'),
      active: isOptionActive(NavbarLinksURLs.Facilities)
    })
    links.push({
      info: { itemId: '/orders' },
      label: t('HeaderNavigation.NavBarLinks.Orders.Text'),
      active: isOptionActive(NavbarLinksURLs.Orders)
    })
    links.push({
      info: { itemId: '/settings/shipper' },
      label: t('HeaderNavigation.NavBarLinks.Settings.Text'),
      active: isOptionActive(NavbarLinksURLs.Settings)
    })
  }

  if (currentUser && !shipperUser && ![YARD_JOCKEY_AUDIENCE].includes(currentUser.userType)) {
    links.push({
      info: { itemId: `/user/${currentUser.id}` },
      label: t('HeaderNavigation.NavBarLinks.UserProfile.Text')
    })
  }

  return links
}

export default NavbarLinks
