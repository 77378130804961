import React from 'react'
import { Block } from 'baseui/block'
import { ArrowLineDown, ArrowLineUp } from '@phosphor-icons/react'
import { FlexGridItem } from 'baseui/flex-grid'
import { StatefulTooltip } from 'baseui/tooltip'
import { DateTime } from 'luxon'
import {
  ContainerProps,
  CustomLabel,
  CustomLabel1,
  CustomLabelCapitalized,
  CustomLabelContainer,
  LabelContainerProps
} from './LabelField.styled'

const getNestedValue = (obj, path) => {
  const value = path.split('.').reduce((acc, part) => acc && acc[part], obj)
  if (path === 'appointmentType.type') {
    return value === 'InboundAppointmentType'
      ? 'Inbound'
      : value === 'OutboundAppointmentType'
      ? 'Outbound'
      : value
  }
  return value
}

const getIcon = obj => {
  return obj.appointmentType.type?.includes('Inbound') ? (
    <ArrowLineDown size={12} color="#082766" />
  ) : (
    <ArrowLineUp size={12} color="#082766" />
  )
}

const LabelField = ({
  pointer,
  field,
  appointment,
  capitalize = false,
  showIcon = false,
  isDate = false
}: {
  pointer: string
  field: string
  appointment: any
  capitalize?: boolean
  showIcon?: boolean
  isDate?: boolean
}) => {
  const value = getNestedValue(appointment, pointer) || 'N/A'
  const Icon = showIcon ? getIcon(appointment) : ''

  return (
    <Block
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      {...ContainerProps}>
      <div>
        <CustomLabel1>{field}</CustomLabel1>
      </div>
      <CustomLabelContainer>
        <Block
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          {...LabelContainerProps}>
          <FlexGridItem
            overrides={{
              Block: {}
            }}>
            <StatefulTooltip accessibilityType={'tooltip'} content={value}>
              {capitalize ? (
                <CustomLabelCapitalized>{value}</CustomLabelCapitalized>
              ) : (
                <CustomLabel>
                  {isDate ? DateTime.fromISO(value).toFormat('MM/dd/yyyy hh:mm a') : value}
                </CustomLabel>
              )}
            </StatefulTooltip>
          </FlexGridItem>
          {showIcon && <FlexGridItem>{Icon}</FlexGridItem>}
        </Block>
      </CustomLabelContainer>
    </Block>
  )
}

export default LabelField
