import DriversCheckin from 'components/pages/DriversCheckin'

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { DriversCheckinProvider } from 'components/contexts/drivers-checkin.context'
import DriversCheckinSearch from 'components/pages/DriversCheckin/DriversCheckinSearch/DriversCheckinSearch'
import DriversCheckinForm from 'components/pages/DriversCheckin/DriversCheckinForm'
import DriversCheckinPostForm from 'components/pages/DriversCheckin/DriversCheckinForm/DriversCheckinPostForm'
import DriversNavbar from 'components/pages/DriversCheckin/DriversNavbar'
import DriversCheckinConfirmation from 'components/pages/DriversCheckin/DriversCheckinForm/DriversCheckinConfirmation'

const DriversCheckinRoutes = ({ match }) => (
  <DriversCheckinProvider>
    <Switch>
      <Route exact path={`${match.url}`} component={DriversCheckinSearch} />
      <Route exact path={`${match.url}/:handle/checkin`} component={DriversCheckinForm} />
      <Route
        exact
        path={`${match.url}/:handle/postcheckin`}
        render={props => <DriversCheckinForm {...props} postCheckin={true} />}
      />
      <Route
        exact
        path={`${match.url}/:handle/details`}
        render={props => <DriversCheckinForm {...props} details={true} />}
      />
    </Switch>
  </DriversCheckinProvider>
)

export default DriversCheckinRoutes
