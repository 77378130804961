import { Block } from 'baseui/block'
import { DriversCheckinContext } from 'components/contexts/drivers-checkin.context'
import React, { useContext } from 'react'
import { Navbar } from '../DriversNavbar'
import LogoHeader from 'components/ui/specific/LogoHeader'
import { HeadingXSmall } from 'baseui/typography'
import { CaretLeft, CheckFat } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import { AppointmentCard } from 'components/components/AppointmentCard'
import { useHistory } from 'react-router-dom'
import {
  DriverConfirmationBody,
  DriverConfirmationIcon,
  DriverConfirmationTitle,
  DynamicLogo
} from './DriversCheckinConfirmation.styled'
import { DROP } from 'components/constants/handling_method'
import { INBOUND_FLAG, OUTBOUND_FLAG } from 'components/constants/appointment_types'

const DriversCheckinConfirmation = () => {
  const { state, actions } = useContext(DriversCheckinContext)
  const { checkinForm, shipper } = state
  const { clearSearch } = actions
  const { t } = useTranslation()
  const history = useHistory()

  const getConfirmationMessage = () => {
    if (
      checkinForm?.appointment?.handlingMethod === DROP &&
      typeof checkinForm?.appointment?.appointmentType == 'object' &&
      (checkinForm?.appointment?.trailer?.dock || checkinForm?.appointment?.trailer?.slot)
    ) {
      return checkinForm?.appointment?.appointmentType?.type === INBOUND_FLAG
        ? t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessageDrop')
        : t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.MessagePick')
    }

    return ''
  }

  return (
    <Block minWidth="94%" padding="scale600">
      <Navbar />
      <LogoHeader
        logo={
          shipper?.activeLogoUrl?.length > 0 ? (
            <DynamicLogo src={shipper?.activeLogoUrl} />
          ) : (
            <HeadingXSmall>{shipper.name}</HeadingXSmall>
          )
        }
      />
      <Block
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        maxWidth="670px"
        margin="auto"
        width="100%"
        padding="24px 32px">
        <Block marginBottom="scale1000">
          <DriverConfirmationIcon>
            <CheckFat size={40} />
          </DriverConfirmationIcon>
        </Block>
        <Block display="flex" flexDirection="column" justifyContent="flex-start">
          <DriverConfirmationTitle>
            {t('DriversCheckin.DriversCheckinConfirmation.LogoHeader.Welcome', {
              shipper: shipper?.name || ''
            })}
          </DriverConfirmationTitle>
          <DriverConfirmationBody>
            {t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccess.Message')}
            {getConfirmationMessage()}
            {checkinForm?.appointment?.handlingMethod === DROP &&
              checkinForm?.appointment?.trailer?.dock?.id && (
                <>
                  <span style={{ fontWeight: 700 }}>
                    {t('Common.Fields.Dock.Text')}, {checkinForm?.appointment?.trailer?.dock?.name}
                  </span>
                  .
                </>
              )}
            {checkinForm?.appointment?.handlingMethod === DROP &&
              checkinForm?.appointment?.trailer?.slot?.id && (
                <>
                  {t('DriversCheckin.DriversCheckinConfirmation.CheckinSuccessLocation.Zone')}{' '}
                  <span style={{ fontWeight: 700 }}>
                    {checkinForm?.appointment?.trailer?.slot?.zone || ''},{' '}
                  </span>
                  {t('Common.Fields.Slot.Text')}{' '}
                  <span style={{ fontWeight: 700 }}>
                    {checkinForm?.appointment?.trailer?.slot?.name || ''}
                  </span>
                  .
                </>
              )}
          </DriverConfirmationBody>
          <Block marginTop="scale800">
            <Block maxWidth={['99%']}>
              {checkinForm.appointment && <AppointmentCard appointment={checkinForm.appointment} />}
            </Block>
          </Block>
          <Block
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="center"
            gridGap="scale500"
            marginTop="scale600">
            <Button
              startEnhancer={<CaretLeft size={16} />}
              onClick={() => {
                clearSearch()
                history.push(`/pass`)
              }}>
              {t('DriversCheckin.DriversCheckinConfirmation.BackToHome.Text')}
            </Button>
            {checkinForm.requirePostCheckin && (
              <Button
                kind="primary"
                onClick={() => {
                  checkinForm.appointment.id &&
                    history.push(`/pass/${checkinForm.appointment.id}/postcheckin`)
                }}>
                {t('DriversCheckin.DriversCheckinConfirmation.MoreInformationButton.Text')}
              </Button>
            )}
          </Block>
        </Block>
      </Block>
    </Block>
  )
}

export default DriversCheckinConfirmation
